<template>
  <div v-loading.fullscreen.lock="loading">
   <div class="header-section">
     <div class="container">
        <div class="row login">
        <div class="col-md-12">
         <div class="row justify-content-center">
           <div class="col-md-8 login-inputs mb-3">
           <login-input></login-input>
          </div>
         </div>
        </div>
        
      </div>
     </div>
    </div>
        <!--Banner Section--->
    <section class="banner-section">
      <div class="container">
        <div class="banner-caption">
          <h2>{{ tour.title }}</h2>
        </div>
      </div>
    </section>
    <!--Banner Section--->
    <div class="balloonimgnew">
      <!-- <div class="balloonimg-two">
        <img src="marketing/images/p-slidebg.png" class="img-fluid" alt="" />
      </div> -->
      <div class="container">
       <div class="row">
          <div class="col-md-10 offset-md-1 col-12">
            <!-- <h3 class="text-center mb-5 mt-4">{{ tour.title }}</h3> -->
            <div class="TourVideo_section">
                <iframe  v-if="tour.videoUrl" :src="'https://player.vimeo.com/video/' + tour.videoUrl" style="border: 0;" width="100%" height="520" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="allowfullscreen" class="iframe-broder text-center">
              </iframe>
            </div>
             
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import LoginInput from "@/views/Pages/LoginInput.vue";
import vueVimeoPlayer from "vue-vimeo-player";
Vue.use(vueVimeoPlayer);

export default {
   components: { LoginInput },
  data() {
    return {
      loading: false,
      tourId: "",
      tour: {
        title: "Tour",
        videoUrl: ""
      }
    };
  },
  created() {
    if (this.$route.query.id) {
      this.tourId = this.$route.query.id;
      this.getTourVimeoId();
    }
  },
  methods: {
    getTourVimeoId() {
      this.$http.get("get_tour_data/" + this.tourId).then(resp => {
        this.tour.title = resp.data.name;
        this.tour.videoUrl = resp.data.vimeo_video_id;
      });
    }
  }
};
</script>
<style scoped>
body,
html {
  height: 100%;
}
.login-inputs {
  padding-top: 20px;
}
.login{
  margin-right: 0px !important;
}
.iframe-broder{
  /* width:150px;
  height:300px; */
  border-radius: 5px;
 }
.balloonimgnew{
    background-color: white;
    padding-top: 40px;
    padding-bottom: 15px;
    background-size: cover;
    background-position: right;
    position: relative;
    background-repeat: no-repeat;
    width: 100%;
    height: 600px;
    min-height: 87vh;
}
.TourVideo_section {
   background-color: #ffffff;
   padding: 10px;
   box-shadow: 0px 0px 11px 3px #0000001a;
}
</style>
